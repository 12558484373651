<template>
  <div class="line-item-detail" v-if="getPermission('line-item:view')">
    <v-container fluid>
      <table class="width-100 custom-border-bottom-5px">
        <thead>
          <tr>
            <th
              width="700"
              :colspan="showcost ? 1 : 2"
              class="font-size-16 pb-2"
            >
              Additional Work
            </th>
            <th v-if="is_admin" class="text-center font-size-16 pb-2">
              Supplier Cost
            </th>
            <th
              :colspan="showcost ? 1 : 2"
              class="text-center font-size-16 pb-2"
              v-if="isQtyAllowed"
            >
              Qty.
            </th>
            <th
              class="text-center font-size-16 pb-2"
              v-if="isRateAllowed && showcost"
            >
              Rate
            </th>
            <th
              class="text-center font-size-16 pb-2"
              v-if="isDiscountAllowed && showcost && discountType == 2"
            >
              Discount[$]
            </th>
            <th
              class="text-center font-size-16 pb-2"
              v-if="isTaxAllowed && showcost && taxType == 2"
            >
              Tax
            </th>
            <th v-if="showcost" class="text-center font-size-16 pb-2">Total</th>
            <th class="text-center font-size-16 pb-2"></th>
          </tr>
          <tr>
            <td
              :colspan="colspan"
              class="custom-border-bottom first-border"
            ></td>
          </tr>
        </thead>
        <tbody>
          <template v-if="lineItemLoading">
            <tr v-for="(df, ind) in 3" :key="ind" class="custom-border-bottom">
              <td width="700" class="font-size-16 pb-2">
                <v-skeleton-loader
                  class="custom-skeleton height-30px my-auto custom-skeleton-full-width"
                  width="100"
                  type="text"
                >
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="custom-skeleton height-30px mr-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td class="text-center font-size-16 pb-2" v-if="isQtyAllowed">
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td class="text-center font-size-16 pb-2" v-if="isRateAllowed">
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td
                class="text-center font-size-16 pb-2"
                v-if="isDiscountAllowed && discountType == 2"
              >
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td
                class="text-center font-size-16 pb-2"
                v-if="isTaxAllowed && taxType == 2"
              >
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td class="text-center font-size-16 pb-2">
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td class="text-center font-size-16 pb-2">
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
            </tr>
          </template>
          <template v-else>
            <template v-if="lodash.isEmpty(lineitems) === false">
              <template v-for="(item, index) in lineitems">
                <tr :key="`div-line-${index}`" v-if="item.group_primary">
                  <td
                    :colspan="colspan"
                    class="group-line-item-label px-4 py-2 font-size-18 font-weight-600"
                  >
                    <v-chip
                      small
                      label
                      v-if="item.group_me"
                      class="mr-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                      text-color="white"
                      color="teal darken-1"
                    >
                      {{ item.group_me }}
                    </v-chip>
                    {{ item.group_name }}
                  </td>
                </tr>
                <v-hover v-slot="lineItemHover" :key="index">
                  <tr class="custom-border-bottom">
                    <td
                      width="700"
                      :colspan="showcost ? 1 : 2"
                      class="font-size-16 font-weight-500 custom-border-right"
                    >
                      <div class="detail-line-item py-4">
                        <v-chip
                          small
                          label
                          v-if="item.group_me"
                          class="mr-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          color="teal darken-1"
                        >
                          {{ item.group_me }}
                        </v-chip>
                        <!-- <v-chip
                          small
                          label
                          v-if="canUpdate && getPermission('line-item:update')"
                          class="mr-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          color="cyan darken-1"
                          v-on:click="updateProductDialog(item)"
                        >
                          Edit
                        </v-chip> -->
                        <v-chip
                          small
                          label
                          class="mr-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          :color="
                            getProductTypeColor(item.product.product_type)
                          "
                        >
                          {{ getProductType(item.product.product_type) }}
                        </v-chip>
                        <v-chip
                          small
                          label
                          class="mr-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          color="green"
                          v-if="item.optional"
                        >
                          Optional
                        </v-chip>
                        <v-hover v-slot="{ hover }" open-delay="200">
                          <div>
                            <LineItemProductDetail
                              v-if="hover"
                              :product="item.product"
                            ></LineItemProductDetail>
                            <p
                              v-on:click="updateProductDialog(item)"
                              class="m-0 pt-1 text-capitalize custom-dashed-border-bottom cursor-pointer custom-nowrap-ellipsis"
                            >
                              {{ item.product.name }}
                            </p>
                          </div>
                        </v-hover>
                        <read-more
                          class="custom-read-more text-capitalize font-weight-600 font-size-16 m-0 pt-1 text--secondary"
                          more-str="read more"
                          :text="item.description ? item.description : ''"
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </div>
                    </td>
                    <td
                      v-if="is_admin"
                      class="text-center font-size-16 pb-2 custom-border-right"
                      width="100px"
                    >
                      {{ formatMoney(item.product.company_cost) }}
                    </td>
                    <td
                      :colspan="showcost ? 1 : 2"
                      class="text-center font-size-16 pb-2 custom-border-right"
                      width="100px"
                      v-if="isQtyAllowed"
                    >
                      {{ item.quantity }}&nbsp;&nbsp;
                      <template v-if="item.product.unit">
                        {{ item.product.unit.text }}
                      </template>
                    </td>
                    <td
                      class="text-center font-size-16 pb-2 custom-border-right"
                      v-if="isRateAllowed && showcost"
                    >
                      <p class="m-0">
                        {{ formatMoney(accountingToFixed(item.rate, 2)) }}
                      </p>
                      <label class="width-100 m-0 pt-2 font-size-14"
                        ><span class="font-weight-600"
                          ><template>Supplier Cost</template>: </span
                        >{{ formatMoney(item.product.company_cost) }}</label
                      >
                      <label class="width-100 m-0 font-size-14"
                        ><span class="font-weight-600"
                          ><template
                            v-if="
                              item.product.product_type == 'Additional Work'
                            "
                            >Price</template
                          ><template v-else>Selling</template>: </span
                        >{{ formatMoney(item.rate) }}</label
                      >
                      <label class="width-100 m-0 font-size-14"
                        ><span class="font-weight-600">Profit: </span
                        >{{
                          formatMoney(item.rate - item.product.company_cost)
                        }}</label
                      >
                    </td>
                    <td
                      class="text-center font-size-16 pb-2 custom-border-right"
                      v-if="isDiscountAllowed && showcost && discountType == 2"
                    >
                      {{ formatMoney(accountingToFixed(item.discount, 2)) }}
                    </td>
                    <td
                      class="text-center font-size-16 pb-2 custom-border-right"
                      v-if="isTaxAllowed && showcost && taxType == 2"
                    >
                      {{ formatMoney(accountingToFixed(item.tax_amount, 2)) }}
                    </td>
                    <td
                      v-if="showcost"
                      class="text-center font-size-16 pb-2 custom-border-right"
                    >
                      <p class="m-0">
                        {{ formatMoney(accountingToFixed(item.total, 2)) }}
                      </p>
                      <label class="width-100 m-0 pt-2 font-size-14"
                        ><span class="font-weight-600"
                          ><template>Supplier Cost</template>: </span
                        >{{
                          formatMoney(item.quantity * item.product.company_cost)
                        }}</label
                      >
                      <label class="width-100 m-0 font-size-14"
                        ><span class="font-weight-600"
                          ><template
                            v-if="
                              item.product.product_type == 'Additional Work'
                            "
                            >Price</template
                          ><template v-else>Selling</template>: </span
                        >{{ formatMoney(item.quantity * item.rate) }}</label
                      >
                      <label class="width-100 m-0 font-size-14"
                        ><span class="font-weight-600">Profit: </span
                        >{{
                          formatMoney(
                            item.quantity *
                              (item.rate - item.product.company_cost)
                          )
                        }}</label
                      >
                    </td>
                    <td
                      v-if="canUpdate && getPermission('line-item:delete')"
                      class="text-center font-size-16 pb-2"
                      width="80"
                    >
                      <template
                        v-if="canUpdate && getPermission('line-item:delete')"
                      >
                        <div
                          v-if="lineItemHover.hover && lineitems.length > 1"
                          class="line-item-float-delete"
                        >
                          <v-btn
                            class="custom-bold-button white--text"
                            color="red lighten-1"
                            fab
                            small
                            v-on:click="deleteLineItem(index)"
                          >
                            <v-icon>mdi-delete-empty</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </td>
                  </tr>
                </v-hover>
              </template>
            </template>
            <tr v-else-if="!lineItemLoading">
              <td class="py-4" :colspan="colspan">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no line item at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-if="canUpdate">
          <template v-if="lineItemLoading">
            <tr>
              <td :colspan="colspan">
                <v-skeleton-loader
                  class="custom-skeleton mx-2 my-2 height-30px custom-skeleton-full-width"
                  width="200"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
            </tr>
          </template>
          <template v-else>
            <!-- <tr v-if="getPermission('line-item:create')">
              <td :colspan="colspan">
                <div>
                  <v-btn
                    class="mR-2 my-2 custom-bold-button white--text"
                    color="cyan"
                    v-on:click="lineItemDialog = true"
                  >
                    <v-icon small left>mdi-format-align-left</v-icon>
                    Add Line Item
                  </v-btn>
                </div>
              </td>
            </tr> -->
          </template>
        </tfoot>
      </table>
    </v-container>
    <v-row>
      <v-col cols="6" class="font-weight-500">
        <template v-if="lineItemLoading">
          <v-skeleton-loader
            class="custom-skeleton height-30px custom-skeleton-full-width"
            type="sentences"
          >
          </v-skeleton-loader>
        </template>
        <v-container v-else fluid>
          <read-more
            class="custom-read-more font-weight-600 font-size-16 pb-1 custom-gray-color"
            more-str="read more"
            :text="detail.additional_remarks ? detail.additional_remarks : ''"
            link="#"
            less-str="read less"
            :max-chars="200"
          >
          </read-more>
        </v-container>
      </v-col>
      <v-col cols="6" class="font-weight-500">
        <table v-if="showcost" class="width-100 vertical-align-middle">
          <tr>
            <td
              valign="center"
              class="font-size-16 py-1 font-weight-500"
              colspan="2"
            >
              Sub Total
            </td>
            <td
              valign="center"
              class="font-size-16 py-1 text-right"
              width="150"
            >
              {{ formatMoney(accountingToFixed(subTotalAmount, 2)) }}
            </td>
          </tr>
          <tr
            v-if="isDiscountAllowed && discountType === 1 && discountAmount > 0"
          >
            <td
              valign="center"
              class="font-size-16 py-1 font-weight-500"
              colspan="2"
            >
              Discount
            </td>
            <td
              valign="center"
              class="font-size-16 py-1 text-right"
              width="150"
            >
              -{{ formatMoney(accountingToFixed(discountAmount, 2)) }}
            </td>
          </tr>
          <tr v-if="isTaxAllowed && taxAmount > 0">
            <td
              valign="center"
              class="font-size-16 py-1 font-weight-500"
              colspan="2"
            >
              Taxable Amount
            </td>
            <td
              valign="center"
              class="font-size-16 py-1 text-right"
              width="150"
            >
              {{ formatMoney(accountingToFixed(taxableAmount, 2)) }}
            </td>
          </tr>
          <tr v-if="isTaxAllowed && taxAmount > 0">
            <td
              valign="center"
              class="font-size-16 py-1 font-weight-500"
              colspan="2"
            >
              Total Tax [{{ accountingToFixed(taxValue, 2) }}%]
            </td>
            <td
              valign="center"
              class="font-size-16 py-1 text-right"
              width="150"
            >
              {{ formatMoney(accountingToFixed(taxAmount, 2)) }}
            </td>
          </tr>
          <tr v-if="allowAdjustment && adjustmentAmount > 0">
            <td
              valign="center"
              class="font-size-16 py-1 font-weight-500"
              colspan="2"
            >
              Adjustment
            </td>
            <td
              valign="center"
              class="font-size-16 py-1 text-right"
              width="150"
            >
              {{ formatMoney(accountingToFixed(adjustmentAmount, 2)) }}
            </td>
          </tr>
          <!-- <tr>
            <td
              valign="center"
              class="font-size-16 py-1 font-weight-700"
              colspan="2"
            >
              Profit
            </td>
            <td
              valign="center"
              class="font-size-16 py-1 text-right font-weight-700"
              width="150"
            >
              {{ formatMoney(accountingToFixed(totalProfit, 2)) }}
            </td>
          </tr> -->
          <tr v-if="is_taxed">
            <td
              valign="middle"
              class="font-size-16 py-1 font-weight-500"
              colspan="2"
            >
              Tax Without Discount
            </td>
            <td
              valign="middle"
              class="font-size-16 py-1 text-right"
              width="150"
            >
              {{ get_tax_without_discount() }}
            </td>
          </tr>
          <tr v-if="is_taxed">
            <td
              valign="middle"
              class="font-size-16 py-1 font-weight-500"
              colspan="2"
            >
              Grand Total (Without Discount)
            </td>
            <td
              valign="middle"
              class="font-size-16 py-1 text-right"
              width="150"
            >
              {{ get_total_without_discount() }}
            </td>
          </tr>
          <tr>
            <td
              valign="middle"
              class="font-size-16 py-1 font-weight-700"
              colspan="2"
            >
              Grand Total (With Discount)
            </td>
            <td
              valign="middle"
              class="font-size-16 py-1 text-right font-weight-700"
              width="150"
            >
              {{ formatMoney(accountingToFixed(grandTotalAmount, 2)) }}
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <template v-if="getPermission('line-item:create')">
      <LineItemSingle
        isNewItem
        :isQuotation.sync="isQuotation"
        :isContract.sync="isContract"
        :isInvoice.sync="isInvoice"
        :isProposal.sync="isProposal"
        :lineItemDialog.sync="lineItemDialog"
        :targetUrl.sync="type"
        :parent.sync="detail.id"
        v-on:success:line-item="successLineItem"
        v-on:close:line-item="lineItemDialog = false"
      ></LineItemSingle>
    </template>
    <template v-if="getPermission('line-item:update')">
      <LineItemUpdate
        :isQuotation.sync="isQuotation"
        :isContract.sync="isContract"
        :isInvoice.sync="isInvoice"
        :isProposal.sync="isProposal"
        :lineItemDialog.sync="lineItemUpdate"
        :targetUrl.sync="type"
        :parent.sync="detail.id"
        :updateData.sync="lineUpdateData"
        v-on:success:line-item="successLineItem"
        v-on:close:line-item="lineItemUpdate = false"
      ></LineItemUpdate>
    </template>
    <template v-if="getPermission('line-item:delete')">
      <LineItemDelete :deleteDialog="deleteLineItemDialog">
        <template v-slot:title>Delete Confirmation</template>
        <template v-slot:text>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you delete
            <span class="font-weight-700">{{ deleteDetail.title }}</span
            >, you won't be able to retrieve this later. Are you sure you want
            to delete
            <span class="font-weight-700">{{ deleteDetail.title }}</span> ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="deleteLoading"
            :loading="deleteLoading"
            class="mx-2 custom-bold-button white--text"
            color="red lighten-1"
            v-on:click="deleteLineItemConfirm"
          >
            Yes, Delete it
          </v-btn>

          <v-btn
            :disabled="deleteLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="deleteLineItemDialog = false"
          >
            No, Keep it
          </v-btn>
        </template>
      </LineItemDelete>
    </template>
  </div>
</template>

<script>
import LineItemDelete from "@/view/pages/partials/Delete.vue";
import LineItemSingle from "@/view/pages/partials/Line-Item-Single.vue";
import LineItemUpdate from "@/view/pages/partials/Line-Item-Single.vue";
import LineItemProductDetail from "@/view/pages/partials/Line-Item-Product-Detail.vue";
import { DELETE, QUERY } from "@/core/services/store/request.module";
import Configuration from "@/core/config/app.config";
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import { currentUser } from "@/core/services/jwt.service";
import ObjectPath from "object-path";

export default {
  mixins: [CommonFunctionMixin],
  data() {
    return {
      lineUpdateData: [],
      deleteLoading: false,
      deleteLineItemDialog: false,
      lineItemDialog: false,
      lineItemUpdate: false,
      deleteDetail: {},
      lineitems: [],
      parent: 0,
      timeout: null,
      lineItemLoading: true,
      is_admin: true,
      is_id_person: true,
      timeoutLimit: 500,
      subTotalAmount: 0.0,
      is_taxed: false,
      discountAmount: 0.0,
      taxValue: 0.0,
      taxableAmount: 0.0,
      taxAmount: 0.0,
      adjustmentAmount: 0.0,
      totalProfit: 0.0,
      grandTotalAmount: 0.0,
    };
  },
  props: {
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isInvoice: {
      type: Boolean,
      default: false,
    },
    isProposal: {
      type: Boolean,
      default: false,
    },
    isContract: {
      type: Boolean,
      default: false,
    },
    showcost: {
      type: Boolean,
      default: true,
    },
    isNewItem: {
      type: Boolean,
      default: false,
    },
    pageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    canUpdate: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      required: true,
      default: "quotation",
    },
  },
  watch: {
    deleteLineItemDialog(param) {
      if (!param) {
        this.deleteDetail = new Object();
      }
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.subTotalAmount = this.lodash.round(param.sub_total, 2);
        this.is_taxed = this.lodash.round(param.is_taxed, 2);
        this.discountAmount = this.lodash.round(param.discount_amount, 2);
        this.taxValue = this.lodash.round(param.tax_value, 2);
        this.taxableAmount =
          this.lodash.round(param.sub_total, 2) -
          this.lodash.round(param.discount_amount, 2);
        this.taxAmount = this.lodash.round(param.tax_amount, 2);
        this.adjustmentAmount = this.lodash.round(param.adjustment, 2);
        this.totalProfit = this.lodash.round(param.total_profit, 2);
        this.grandTotalAmount = this.lodash.round(param.total, 2);
        this.parent = this.lodash.toSafeInteger(param.id);
        this.getLineItemList();
      },
    },
  },
  methods: {
    isGroup(item) {
      if (item.group_me) {
        if (item.group_primary) {
          return true;
        }
        return false;
      }
      return true;
    },
    get_tax_without_discount() {
      if (this.is_taxed) {
        let taxableAmount = this.subTotalAmount;
        return this.formatMoney((taxableAmount * 7) / 100);
      }
    },
    get_total_without_discount() {
      let taxAmount = 0;
      if (this.is_taxed) {
        let taxableAmount = this.subTotalAmount;
        taxAmount = (taxableAmount * 7) / 100;
      }
      return this.formatMoney(
        taxAmount + this.subTotalAmount + this.adjustmentAmount
      );
    },
    getProductType(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "Product";
          break;
        case "service":
          result = "Additional Work";
          break;
        case "package":
          result = "Package";
          break;
      }
      return result;
    },
    getProductTypeColor(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "cyan darken-1";
          break;
        case "service":
          result = "orange darken-1";
          break;
        case "package":
          result = "green darken-1";
          break;
      }
      return result;
    },
    updateProductDialog(item) {
      if (this.canUpdate && this.getPermission("line-item:update")) {
        this.lineUpdateData = new Array(item);
        this.lineItemUpdate = true;
      }
    },
    pushToDetail() {},
    getLineItems(parent) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (parent) {
            let formData = {};
            formData[_this.type] = parent;
            _this.$store
              .dispatch(QUERY, {
                url: "line-item",
                data: formData,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Parent is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    successLineItem() {
      this.getLineItemList();
      this.deleteLineItemDialog = false;
      this.lineItemDialog = false;
      this.lineItemUpdate = false;
      this.$emit("load:parent");
    },
    deleteLineItem(index) {
      this.deleteDetail = new Object();
      if (this.lodash.isEmpty(this.lineitems[index]) === false) {
        this.deleteLineItemDialog = true;
        let title = new Array();
        if (this.lodash.isEmpty(this.lineitems[index].product) === false) {
          title.push(
            this.lineitems[index].product.product_type == "goods"
              ? "Product"
              : "Service"
          );
          title.push(this.lineitems[index].product.name);
        }
        this.deleteDetail = {
          index: index,
          id: this.lineitems[index].id,
          title: title.join(" - "),
        };
      }
    },
    deleteLineItemConfirm() {
      const _this = this;
      const deletePromise = () => {
        return new Promise((resolve, reject) => {
          try {
            let lineItem = _this.lodash.toSafeInteger(_this.deleteDetail.id);
            if (lineItem > 0) {
              _this.$store
                .dispatch(DELETE, { url: "line-item/" + lineItem })
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              reject("invalid line item id");
            }
          } catch (error) {
            reject(error);
          }
        });
      };
      if (_this.lodash.isEmpty(_this.deleteDetail) === false) {
        _this.deleteLoading = true;
        deletePromise()
          .then(() => {
            _this.lineitems.splice(_this.deleteDetail.index, 1);
            _this.deleteLineItemDialog = false;
            _this.getLineItemList();
            _this.$emit("load:parent");
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.deleteLoading = false;
            _this.deleteDetail = new Object();
          });
      }
    },
    getLineItemList() {
      const _this = this;
      clearTimeout(_this.timeout);
      _this.lineItemLoading = true;
      _this.timeout = setTimeout(function () {
        _this
          .getLineItems(_this.parent)
          .then((response) => {
            _this.lineitems = response.rows;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.lineItemLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    this.getLineItemList();

    const user = currentUser();
    const role_name = ObjectPath.get(user, "role_name");
    if (this.lodash.toLower(role_name) == "admin") {
      this.is_admin = true;
    }
    if (this.lodash.toLower(role_name) == "id") {
      this.is_id_person = true;
    }
  },
  components: {
    LineItemDelete,
    LineItemSingle,
    LineItemUpdate,
    LineItemProductDetail,
  },
  computed: {
    discountType() {
      return this.lineItemConfig
        ? this.lodash.toSafeInteger(this.lineItemConfig.discount_type)
        : 0;
    },
    taxType() {
      return this.lineItemConfig
        ? this.lodash.toSafeInteger(this.lineItemConfig.tax_type)
        : 0;
    },
    showEngineerRate() {
      return this.lineItemConfig
        ? !!+this.lodash.toSafeInteger(this.lineItemConfig.allow_engineer_rate)
        : false;
    },
    allowAdjustment() {
      return this.lineItemConfig
        ? !!+this.lodash.toSafeInteger(this.lineItemConfig.allow_adjustment)
        : false;
    },
    isGroupAllowed() {
      if (
        this.lineItemConfig &&
        this.lodash.isEmpty(this.lineItemConfig) === false
      ) {
        if (!this.lineItemConfig.allow_item_group) {
          return false;
        }
        if (this.isQuotation) {
          if (this.lineItemConfig.quotation_item_group) {
            return true;
          }
          return false;
        }
        if (this.isInvoice) {
          if (this.lineItemConfig.invoice_item_group) {
            return true;
          }
          return false;
        }
        if (this.isProposal) {
          if (this.lineItemConfig.proposal_item_group) {
            return true;
          }
          return false;
        }
        if (this.isContract) {
          if (this.lineItemConfig.contract_item_group) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
    isQtyAllowed() {
      if (
        this.lineItemConfig &&
        this.lodash.isEmpty(this.lineItemConfig) === false
      ) {
        if (!this.lineItemConfig.allow_qty) {
          return false;
        }
        if (this.isQuotation) {
          if (this.lineItemConfig.quotation_qty) {
            return true;
          }
          return false;
        }
        if (this.isInvoice) {
          if (this.lineItemConfig.invoice_qty) {
            return true;
          }
          return false;
        }
        if (this.isProposal) {
          if (this.lineItemConfig.proposal_qty) {
            return true;
          }
          return false;
        }
        if (this.isContract) {
          if (this.lineItemConfig.contract_qty) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
    isRateAllowed() {
      if (
        this.lineItemConfig &&
        this.lodash.isEmpty(this.lineItemConfig) === false
      ) {
        if (!this.lineItemConfig.allow_rate) {
          return false;
        }
        if (this.isQuotation) {
          if (this.lineItemConfig.quotation_rate) {
            return true;
          }
          return false;
        }
        if (this.isInvoice) {
          if (this.lineItemConfig.invoice_rate) {
            return true;
          }
          return false;
        }
        if (this.isProposal) {
          if (this.lineItemConfig.proposal_rate) {
            return true;
          }
          return false;
        }
        if (this.isContract) {
          if (this.lineItemConfig.contract_rate) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
    isDiscountAllowed() {
      if (
        this.lineItemConfig &&
        this.lodash.isEmpty(this.lineItemConfig) === false
      ) {
        if (!this.lineItemConfig.allow_discount) {
          return false;
        }
        if (this.isQuotation) {
          if (this.lineItemConfig.quotation_discount) {
            return true;
          }
          return false;
        }
        if (this.isInvoice) {
          if (this.lineItemConfig.invoice_discount) {
            return true;
          }
          return false;
        }
        if (this.isProposal) {
          if (this.lineItemConfig.proposal_discount) {
            return true;
          }
          return false;
        }
        if (this.isContract) {
          if (this.lineItemConfig.contract_discount) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
    isTaxAllowed() {
      if (
        this.lineItemConfig &&
        this.lodash.isEmpty(this.lineItemConfig) === false
      ) {
        if (!this.lineItemConfig.allow_tax) {
          return false;
        }
        if (this.isQuotation) {
          if (this.lineItemConfig.quotation_tax) {
            return true;
          }
          return false;
        }
        if (this.isInvoice) {
          if (this.lineItemConfig.invoice_tax) {
            return true;
          }
          return false;
        }
        if (this.isProposal) {
          if (this.lineItemConfig.proposal_tax) {
            return true;
          }
          return false;
        }
        if (this.isContract) {
          if (this.lineItemConfig.contract_tax) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
    colspan() {
      let colspan = 7;
      if (!this.isQtyAllowed) {
        --colspan;
      }
      if (!this.isRateAllowed) {
        --colspan;
      }
      if (!this.isDiscountAllowed && this.discountType == 2) {
        --colspan;
      }
      if (!this.isTaxAllowed && this.taxType == 2) {
        --colspan;
      }
      return colspan;
    },
  },
  beforeMount() {
    this.lineItemConfig = Configuration.getLineItemConfig();
  },
};
</script>
